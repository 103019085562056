import React from "react";
import { DefaultLayout } from "@layout";
import { Section, Container, RawText, RichText, Pattern } from "@atoms";

/* eslint react/prop-types: 0 */
const GeneralPage = ({ data, ...pageProps }) => {
  const { hero, body, meta } = data;

  return (
    <DefaultLayout {...pageProps} meta={meta} theme="light">
      {/* Hero */}
      <Section className="relative bg-gray-5" size="xl">
        <Container size="md">
          <RawText items={hero.heading.raw} className="text-green-2 max-w-sm" />
        </Container>

        <Pattern
          orientation="bottom-left"
          size="sm"
          className="text-gray-3"
          doAnimation
        />
      </Section>

      {/* Body */}
      <Section className="bg-white">
        <Container size="md">
          {body.map(slice => (
            <RichText
              key={slice.id}
              html={slice.copy.html}
              className="RichText--slice text-gray"
            />
          ))}
        </Container>
      </Section>
    </DefaultLayout>
  );
};

export default GeneralPage;
