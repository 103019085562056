import React from "react";
import { graphql } from "gatsby";
import {
  mergePrismicPreview,
  keysToCamel,
  resolveSlices,
  resolveImage,
  resolveByPrefix,
  resolveExtraGroups,
} from "@dataResolvers";
import GeneralPage from "../../pageTemplates/GeneralPage";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicGeneralPages }) => {
  const { prefixed, unPrefixed } = resolveByPrefix(prismicGeneralPages.data, [
    "meta_",
  ]);
  const { meta } = prefixed;
  const { hero: _hero, body } = unPrefixed;
  const { hero } = resolveExtraGroups({ hero: _hero });

  return keysToCamel({
    hero,
    body: resolveSlices(body),
    meta: {
      ...meta,
      title: meta.title || hero.heading.text,
      image: resolveImage(meta.image),
    },
  });
};

const QueryContainer = ({ data: _data, ...rest }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <GeneralPage data={data} {...rest} />;
};

export default QueryContainer;

export const query = graphql`
  query($uid: String!) {
    prismicGeneralPages(uid: { eq: $uid }) {
      id
      uid
      data {
        hero {
          heading {
            raw
            text
          }
        }
        body {
          ... on PrismicGeneralPagesBodyRichText {
            id
            slice_type
            primary {
              copy {
                html
              }
            }
          }
        }
        meta_title
        meta_description
        meta_image {
          alt
          url
          dimensions {
            height
            width
          }
        }
      }
    }
  }
`;
